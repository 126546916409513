body {
  margin: 0;
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Header{
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
}
.HeaderContent{
  background-color: white;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.HeaderAvatar{
  width: 100px;
  margin: 0 30px 0 10px;
}
.AvatarImage{
  border-radius: 100%;
  width: 100%;
}
.HeaderText{
  font-size: 1.8em;
  font-weight: lighter;
  height: 2.4em;
  width: 13em;
}
@media (max-width: 600px) {
  .HeaderAvatar{
    width: 60px;
    margin: 0 20px 0 10px;
  }
  .HeaderText {
    font-size: 1.3em;
  }
}
.AnimatedText{
  white-space: pre-wrap;
}
.PortfolioItemsHolder{

}
.PortfolioItem{
  padding: 30px;
  margin: 30px;
}
.PortfolioItemSubtitle{
  margin: 10px 0 !important;
  color: #545454;
}
.PortfolioItemButton{
  margin: 25px 25px 25px 0 !important;
}
.PortfolioItemMainText{
  margin: 50px 0!important;
}
.PortfolioItemImage{
  max-width: 1920px;
  max-height: 720px;
}
.PortfolioItemList{
  padding: 0;
}
.PortfolioItemListItem{
  margin: 20px 0;
  list-style-type: none;
}
@media (max-width: 600px) {
  .PortfolioItem{
    padding: 25px;
    margin: 30px 10px;
  }
  .PortfolioItemMainText{
    font-size: 1.7em!important;
  }
}
.bold{
  font-weight: bold!important;
}
.hr{
  margin: 50px 0;
  background-color: #cbcbcb;
  height: 1px;
  border: 0;
}
.mt-50{
  margin-top: 50px!important;
}
.Quote{
  margin: 50px 0;
  padding: 50px 15vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2em;
  min-height: 8em;
}
.QuoteText{
  min-height: 5em;
  line-height: 1.3em;
}
.quoteName{
  margin: 10px 0;
  font-size: 0.8em;
}
@media (max-width: 600px) {
  .Quote{
    padding: 30px 15px;
  }
}
.italic{
  font-style: italic;
}
.Footer{
  padding: 30px;
  margin: 30px;
}
.FooterAvatarSection{
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.FooterAvatarImageHolder{
  height: 50px;
  width: 50px;
  margin-right: 15px;
}
.FooterAvatarTextHolder{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.FooterAvatarTextPrimary{
  font-size: 1em;
}
.FooterAvatarTextSecondary{
  color: #777777;
  font-size: 0.9em;
}
.FooterMessage{
  margin: 50px;
  text-align: center;
  font-size: 2em;
}
.FooterLinkSection{

}
.EmailButton{
  margin-right: 20px !important;
}
.LinkedInButton {
  background-color: #0e76a8 !important;
}
.GithubButton {
  background-color: #2c2c2c !important;
  margin-right: 20px !important;
}
.LinkedInIcon {
  color: white !important;
}
.ButtonInfoText{
  color: #8c8c8c;
  display: inline;
}